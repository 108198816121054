import React, { useEffect, useState } from 'react';
import classNames from 'classnames/bind';

import Button from '@/components/form/button/Button';
import PageHeader from '@/components/typography/page-header/PageHeader';
import Skeleton from '@/components/loading/skeleton/Skeleton';
import useHTTP from '@/hooks/useHTTP';
import { AuthState, selectAuth } from '@/store/auth';
import { GenreState, selectGenre } from '@/store/genre';
import { useAppSelector } from '@/store/hooks';

const cx = classNames.bind(styles);

import styles from './styles.module.scss';

const FeaturedGenres: React.FC = () => {
    const { authenticated } = useAppSelector<AuthState>(selectAuth);
    const { userGenres } = useAppSelector<GenreState>(selectGenre);

    const [results, setResults] = useState<Genre[]>([]);

    const getHeaderText = () => {
        if (userGenres?.length) {
            return 'By your genres';
        } else {
            return 'By Genre';
        }
    };

    const { data, loading, error } = useHTTP({
        request: {
            endpoint: '/genres',
            requestKey: 'index-featured-genres',
            params: {
                _sort: 'name:ASC',
                featured_eq: true,
            },
        },
        method: 'get',
    });

    useEffect(() => {
        let genres: Genre[] = [];

        if (authenticated) {
            if (userGenres && userGenres.length) genres = userGenres;
        }

        if (!loading && !error && data && !userGenres?.length) {
            genres = data;
        }

        setResults(genres);
    }, [authenticated, data, error, loading, userGenres]);

    return (
        <section className={cx('__featured-genres')}>
            {results ? (
                <>
                    <PageHeader
                        h2={getHeaderText()}
                        h2Href='/genres'
                        subHeading='Browse festivals by your favorite genres'
                    />
                    <div className={cx('__genres', 'custom__scrollbar')}>
                        {results.map((g: Genre) => (
                            <Button
                                key={`featured-genres-${g.slug}`}
                                label={g.name}
                                href={`/festivals/genre/${g.slug}`}
                                theme='secondary'
                                size='small'
                                customClassName={cx('genre__label')}
                            />
                        ))}
                    </div>
                </>
            ) : (
                <>
                    <div>
                        <Skeleton
                            bones={[
                                { customClass: 'h-9 md:h-11 w-full md:w-72' },
                                {
                                    customClass:
                                        'h-6 w-32 md:w-48 mt-2 md:mt-4',
                                },
                            ]}
                            theme='light'
                        />
                    </div>
                    <div className={cx('__genres', 'custom__scrollbar')}>
                        <Skeleton
                            bones={[
                                { customClass: 'h-10 w-40' },
                                { customClass: 'h-10 w-32' },
                                { customClass: 'h-10 w-40' },
                                { customClass: 'h-10 w-24' },
                                { customClass: 'h-10 w-28' },
                                { customClass: 'h-10 w-32' },
                                { customClass: 'h-10 w-40' },
                            ]}
                            theme='light'
                        />
                    </div>
                </>
            )}
        </section>
    );
};

export default React.memo(FeaturedGenres);
