import classNames from 'classnames/bind';

import About from '@/components/home/About';
import FeaturedGenres from '@/components/home/FeaturedGenres';
import PageHeader from '@/components/typography/page-header/PageHeader';
import SeoHead from '@/components/chrome/seo-head/SeoHead';
import FeaturedCarousel from '@/components/home/FeaturedCarousel';
import { getGreeting } from '@/common/user';
import { GROUPED_MAX_PER_ROW } from '@/utils/constants';
import { isAuthenticated } from '@/utils/http';
import { parseError } from '@/utils/apiErrorHandler';
import { selectUser } from '@/store/user';
import { useAppSelector } from '@/store/hooks';

import styles from './styles.module.scss';

const cx = classNames.bind(styles);

interface Props {
    authenticated: boolean;
}

const Index: React.FC = ({ authenticated }: Props) => {
    const { firstName } = useAppSelector(selectUser);

    return (
        <>
            <SeoHead
                title={`FestGPS: Music Festival Finder | The world's greatest music festival app`}
                suffixTitle={false}
            />
            <div id='home' className={cx('home-container')}>
                <div className={cx('content')}>
                    {!authenticated ? (
                        <About />
                    ) : (
                        <PageHeader
                            tag={firstName ? getGreeting() : undefined}
                            h1={firstName ? firstName : getGreeting()}
                        />
                    )}
                    <FeaturedGenres />
                    {authenticated && (
                        <FeaturedCarousel
                            h2='Your Top Festivals'
                            h2Href='user/recommended/festivals'
                            subHeading='Recommended festivals based on your favorite artists'
                            endpoint='/recommended/festivals'
                            maxPerRow={GROUPED_MAX_PER_ROW}
                            type='festival'
                        />
                    )}
                    <FeaturedCarousel
                        h2='Featured'
                        subHeading='The festie starter package'
                        endpoint='/festivals'
                        params={{
                            _limit: GROUPED_MAX_PER_ROW,
                            _sort: 'id:DESC',
                            _filter: { upcoming: 'ASC' },
                            featured_eq: true,
                        }}
                        requestKey='home-featured-festivals'
                        maxPerRow={GROUPED_MAX_PER_ROW}
                        type='festival'
                    />
                    <FeaturedCarousel
                        h2='Fest Digest'
                        h2Href='/blog'
                        subHeading='FestGPS news and updates'
                        endpoint='/posts'
                        requestKey='home-news-updates'
                        noResultsTitle='No updates posted yet'
                        noResultsMessage='Get notified when your favorites are playing together and other relevant news.'
                        noResultsButtonLabel='Sign up'
                        noResultsButtonHref='/auth/sign-up'
                        maxPerRow={GROUPED_MAX_PER_ROW}
                        type='post'
                    />
                    <FeaturedCarousel
                        h2='Upcoming'
                        subHeading={`Don't miss out on these`}
                        endpoint='/festivals'
                        params={{
                            _limit: GROUPED_MAX_PER_ROW,
                            _filter: { upcoming: 'ASC' },
                            featured_eq: false,
                        }}
                        requestKey='home-upcoming-festivals'
                        maxPerRow={GROUPED_MAX_PER_ROW}
                        type='festival'
                    />
                    <FeaturedCarousel
                        h2='Popular'
                        subHeading='Users seem to like these'
                        endpoint='/festivals'
                        params={{
                            _limit: GROUPED_MAX_PER_ROW,
                            _sort: 'id:DESC',
                            featured_eq: false,
                        }}
                        requestKey='home-popular-festivals'
                        maxPerRow={GROUPED_MAX_PER_ROW}
                        type='festival'
                    />
                    <FeaturedCarousel
                        h2='On Tour'
                        subHeading='Popular acts'
                        endpoint='/artists/touring'
                        requestKey='home-touring-artists'
                        maxPerRow={GROUPED_MAX_PER_ROW}
                        type='artist'
                    />
                </div>
            </div>
        </>
    );
};

export async function getServerSideProps({ req }) {
    try {
        const authenticated = isAuthenticated(req);

        return {
            props: {
                authenticated,
            },
        };
    } catch (error) {
        parseError({ error, throwError: false, trackError: false });

        return {
            props: {
                authenticated: false,
            },
        };
    }
}

export default Index;
