import React from 'react';

import classNames from 'classnames/bind';

import Button from '@/components/form/button/Button';
import { Events, trackEvent } from '@/utils/analytics';
import { Pages } from '@/utils/constants';

import styles from './styles.module.scss';
const cx = classNames.bind(styles);

const About: React.FC = () => {
    return (
        <section className={cx('__about')}>
            <h1 className={cx('__header')}>
                {`FestGPS is the world's greatest music festival app.`}
            </h1>
            <p className={cx('__text')}>
                Integrating with streaming service data to instantly show the
                best festivals, flights and accommodations for each user,
                FestGPS is the ultimate music festival recommendation engine.
            </p>
            <div className={cx('__button_container')}>
                <div className={cx('__button')}>
                    <Button
                        href='https://links.festgps.app/download'
                        theme='outline'
                        label='Get the app'
                        wrap={false}
                        onPress={() => {
                            trackEvent(Events.ClickedGetTheApp, {
                                page: Pages.Home,
                            });
                        }}
                    />
                </div>
            </div>
        </section>
    );
};

export default React.memo(About);
