import { Greetings } from '@/utils/constants';

export const getGreeting = () => {
    const time = new Date();
    const hours = time.getHours();

    if (hours >= 0 && hours < 12) return Greetings.GoodMorning;
    if (hours >= 12 && hours < 18) return Greetings.GoodAfternoon;
    if (hours >= 18 && hours < 21) return Greetings.GoodEvening;
    if (hours >= 21 && hours < 23) return Greetings.GoodNight;

    return Greetings.GoodDay;
};
